<template>
  <v-alert type="info" variant="outlined"
    >You are editing the internal settings for site "<kbd>{{ siteName }}</kbd
    >".</v-alert
  >
  <h2 class="mt-5">Tips</h2>
  <div class="d-flex justify-start flex-wrap">
    <v-switch v-model="uiSettings.tips.enabled" label="Enable tips for site" inset color="success" class="mx-2" />
    <div class="d-flex justify-space-evenly flex-grow-1 flex-wrap">
      <v-select
        v-if="uiSettings.tips.enabled"
        v-model="uiSettings.tips.provider"
        label="Select the tip provider"
        :items="tipsProviders"
        :error-messages="(v$.tips.provider.$silentErrors.map((e) => e.$message) as string[]) || []"
        class="mx-2"
        style="min-width: 200px"
      />
      <v-text-field
        v-if="uiSettings.tips.enabled && uiSettings.tips.provider === 'tipjar'"
        v-model="uiSettings.tips.tipjarCode"
        label="TipJar's shortcode for site"
        clearable
        :error-messages="(v$.tips.tipjarCode.$silentErrors.map((e) => e.$message) as string[]) || []"
        class="mx-2"
        style="min-width: 230px"
      />
    </div>
  </div>
  <div class="d-flex justify-end">
    <v-checkbox
      v-if="uiSettings.tips.enabled"
      v-model="uiSettings.tips.skipTipsIfServiceChargeIncluded"
      label="Skip tip modal when service charge is included"
      inset
      color="primary"
      class="mx-2"
    />
  </div>
  <div class="d-flex justify-end">
    <v-btn :loading="updateLoading" :disabled="!settingsChanged || v$.$invalid" @click="updateInternalSettings">save changes</v-btn>
  </div>
  <internal-connect-settings :site-id="siteId" />
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch, inject } from 'vue';
import { useRoute } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import { useApplicationStore } from '@/store/application';
import { Settings } from '@/api/merchant-service/site-internal-settings';
import { API } from '@/plugins/api';
import InternalConnectSettings from '@/components/SiteSettings/InternalConnectSettings.vue';
const api = inject<API>('api');

const applicationStore = useApplicationStore();
const siteId = useRoute().params.siteId as string;

const siteName = ref<string | undefined>(undefined);
const fetchedSettings = ref<Settings | undefined>(undefined);
onMounted(async () => {
  const internalSiteSettings = await api!.siteInternalSettings.getSiteSettings(siteId);
  siteName.value = internalSiteSettings.name;
  fetchedSettings.value = internalSiteSettings.internalSettings;
  uiSettings.value = {
    tips: {
      enabled: !!fetchedSettings.value?.tips?.provider,
      provider: fetchedSettings.value?.tips?.provider || '',
      tipjarCode: fetchedSettings.value?.tips?.tipjarShortcode || '',
      skipTipsIfServiceChargeIncluded: fetchedSettings.value?.tips?.skipTipsIfServiceChargeIncluded || false,
    },
  };
});

const updateLoading = ref(false);
const updateInternalSettings = async () => {
  updateLoading.value = true;
  try {
    await api!.siteInternalSettings.updateSiteSettings(siteId, updatedSettings.value);
    fetchedSettings.value = updatedSettings.value;
    applicationStore.notifyUser({ message: 'Internal settings for site saved' });
  } catch (error) {
    console.error('Failed to save internal settings for site', error);
    applicationStore.notifyUser({ message: 'Failed to save internal settings for site', type: 'error' });
  } finally {
    updateLoading.value = false;
  }
};

const uiSettings = ref({
  tips: {
    enabled: false,
    provider: '',
    tipjarCode: '',
    skipTipsIfServiceChargeIncluded: false,
  },
});
const rules = {
  tips: {
    enabled: {},
    provider: { required: requiredIf(() => uiSettings.value.tips.enabled) },
    tipjarCode: { required: requiredIf(() => uiSettings.value.tips.enabled && uiSettings.value.tips.provider === 'tipjar') },
  },
};
const v$ = useVuelidate(rules, uiSettings);
watch(
  () => uiSettings.value.tips.provider,
  (newValue) => {
    if (newValue === 'yetipay') {
      uiSettings.value.tips.tipjarCode = '';
    }
  },
);
const updatedSettings = computed<Settings>(() => ({
  tips:
    (uiSettings.value.tips.enabled && {
      provider: uiSettings.value.tips.provider as 'tipjar' | 'yetipay',
      tipjarShortcode: uiSettings.value.tips.tipjarCode === '' ? undefined : uiSettings.value.tips.tipjarCode,
      skipTipsIfServiceChargeIncluded: uiSettings.value.tips.skipTipsIfServiceChargeIncluded || undefined,
    }) ||
    undefined,
}));
const settingsChanged = computed(() => JSON.stringify(updatedSettings.value) !== JSON.stringify(fetchedSettings.value));
const tipsProviders = ref([
  { title: 'TipJar', value: 'tipjar' },
  { title: 'Yetipay', value: 'yetipay' },
]);
</script>
