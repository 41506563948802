import vuetify from '@/plugins/vuetify';
import pinia from '@/store';
import api from '@/plugins/api';
import posthogPlugin from '@/plugins/posthog';
import cookies from '@/plugins/cookie-consent';

import type { App } from 'vue';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export function registerPlugins(app: App) {
  app.component('VueDatePicker', VueDatePicker);

  app.use(vuetify).use(pinia).use(cookies).use(posthogPlugin).use(api);
}
