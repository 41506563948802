<template>
  <p class="no-word-break">
    You'll need to add a billing payment method to use the yetipay.me platform. You'll be billed monthly using the payment method you add here.
  </p>
  <p class="no-word-break">Read our <a href="https://yetipay.me/terms" target="_blank">yetipay.me terms</a> for more information.</p>

  <StripeBillingWidget v-if="showPaymentWidget && merchantsStore.currentMerchant" @error="onPaymentWidgetError" />
  <h2 v-else-if="paymentError">{{ paymentError }}</h2>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useMerchantsStore } from '@/store/merchants';

import StripeBillingWidget from '@/components/Billing/StripePaymentWidget.vue';

const merchantsStore = useMerchantsStore();
const paymentError = ref('');
const customerHadValidPaymentMethod = ref(false);

const showPaymentWidget = computed(() => !customerHadValidPaymentMethod.value && !paymentError.value);

const onPaymentWidgetError = (error: { message: string }) => {
  console.log('payment widget error', error);
  const genericErrorMessage = 'Something went wrong, please try again later. If the issue persists, please contact Yetipay support.';
  paymentError.value = error.message || genericErrorMessage;
};
</script>
