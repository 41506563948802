import { myAxios } from '@/plugins/api';

export interface GetSettingsResponse {
  name: string;
  internalSettings: Settings;
}

export interface Settings {
  tips?: {
    provider: 'tipjar' | 'yetipay';
    tipjarShortcode?: string;
    skipTipsIfServiceChargeIncluded?: boolean;
  };
}

export interface SiteConnectConfig {
  connection_id: string;
  site_id: 'string';
  authentication: {
    redacted_token: string;
  };
}

class SiteInternalSettingsApi {
  private readonly merchantId: () => string;

  constructor(merchantId: () => string) {
    this.merchantId = merchantId;
  }

  public async getSiteSettings(siteId: string): Promise<GetSettingsResponse> {
    return myAxios.get<GetSettingsResponse>(`/v1/merchants/${this.merchantId()}/sites/${siteId}/internal`).then((response) => response.data);
  }

  public async updateSiteSettings(siteId: string, request: Settings): Promise<Settings> {
    return myAxios.put(`/v1/merchants/${this.merchantId()}/sites/${siteId}/internal`, request);
  }

  public async fetchIntegrationConfig(siteId: string): Promise<SiteConnectConfig> {
    return myAxios.get(`/v1/merchants/${this.merchantId()}/sites/${siteId}/connect-config`).then((response) => response.data.responseBody);
  }
}

export default SiteInternalSettingsApi;
