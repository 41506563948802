<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <v-card v-if="!showConfirmationForm" class="pa-5 mt-12">
          <v-card-title>
            <h2 class="font-weight-medium">Create account</h2>
          </v-card-title>
          <v-card-text class="mt-5">
            <div v-if="isGoogleLoginEnabled" align="center"><GoogleSSO flow="up" /></div>
            <br v-if="isGoogleLoginEnabled" />
            <v-divider v-if="isGoogleLoginEnabled" />
            <br v-if="isGoogleLoginEnabled" />
            <v-form @submit.prevent="signUp">
              <v-text-field
                v-model="email"
                placeholder="Email"
                required
                density="compact"
                autocapitalize="none"
                autocomplete="email"
                type="email"
                :error-messages="(v$.email.$dirty && v$.email.$invalid && v$.email.$silentErrors[0].$message.toString()) || ''"
                @input="authStore.resetErrorMessages"
              ></v-text-field>
              <v-text-field
                v-model="password"
                placeholder="Password"
                required
                persistent-placeholder
                density="compact"
                type="password"
                autocapitalize="none"
                autocomplete="password"
                :error-messages="(v$.password.$dirty && v$.password.$invalid && v$.password.$silentErrors[0].$message.toString()) || ''"
                @input="authStore.resetErrorMessages"
              ></v-text-field>
              <v-row>
                <v-col cols="6" md="5">
                  <v-select
                    v-model="phoneCountryCode"
                    :items="phoneCountryCodes"
                    item-title="text"
                    hint="Country code"
                    persistent-hint
                    density="compact"
                    required
                    @input="authStore.resetErrorMessages"
                  />
                </v-col>
                <v-col cols="6" md="7">
                  <v-text-field
                    v-model="phoneNumber"
                    type="number"
                    density="compact"
                    placeholder="Phone number"
                    :prefix="phoneCountryCode"
                    persistent-placeholder
                    required
                    autocomplete="phone-number"
                    class="no-inline-number-controls"
                    :error-messages="(phoneNumber.length > 0 && v$.phoneNumber.$invalid && v$.phoneNumber.$silentErrors[0].$message.toString()) || ''"
                    @input="authStore.resetErrorMessages"
                  />
                </v-col>
              </v-row>
              <v-btn :disabled="isFormDisabled" class="mt-5" type="submit" block>Create Account</v-btn>
              <v-alert v-if="authStore.signUp.errorMessage" density="compact" outlined type="warning" class="mt-4">
                {{ authStore.signUp.errorMessage }}
              </v-alert>
            </v-form>
          </v-card-text>
          <v-card-text>
            <p class="text-left">Already got an account? <router-link to="/signin">Sign in</router-link></p>
          </v-card-text>
        </v-card>
        <ConfirmUser v-else :email="email" @confirmation-success="userIsConfirmed" @exit="showConfirmationForm = false" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email as emailValidator, helpers } from '@vuelidate/validators';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import { phoneCountryCodes } from '@/utils/config';
import { useAuthenticationStore, LOADING_STATES } from '@/store/authentication';
import { useApplicationStore } from '@/store/application';
import { passwordValidations } from '@/utils/custom-validators';
import { parsePhoneNumber } from 'awesome-phonenumber';
import ConfirmUser from '@/components/Authentication/ConfirmUser.vue';
import GoogleSSO from '@/components/Authentication/GoogleSSO.vue';

const authStore = useAuthenticationStore();
const applicationStore = useApplicationStore();
const route = useRoute();
const router = useRouter();

const showConfirmationForm = ref(false);
const email = ref('');
const password = ref('');
const phoneCountryCode = ref('+44');
const phoneNumber = ref('');

const v$ = useVuelidate(
  {
    email: {
      $autoDirty: true,
      required: helpers.withMessage('Required', required),
      email: helpers.withMessage('Invalid email address', emailValidator),
    },
    password: {
      $autoDirty: true,
      ...passwordValidations,
    },
    phoneNumber: {
      $autoDirty: true,
      required: helpers.withMessage('Required', required),
      phoneNumberValidator: helpers.withMessage('Please enter a valid phone number', () => parsePhoneNumber(phoneCountryCode.value + phoneNumber.value).valid),
    },
  },
  reactive({
    email,
    password,
    phoneNumber: phoneCountryCode.value + phoneNumber.value,
  }),
);

const isFormDisabled = computed(() => v$.value.$invalid || authStore.signUp.status === LOADING_STATES.LOADING);
const isGoogleLoginEnabled = computed(() => route.query.g === null);

const signUp = async () => {
  if (!(await v$.value.$validate())) {
    return;
  }
  const emailLower = email.value.toLowerCase();
  const phoneNumberFull = phoneCountryCode.value + phoneNumber.value;
  const signUpSuccess = await authStore.initSignUp({ email: emailLower, password: password.value, phoneNumber: phoneNumberFull });

  if (signUpSuccess) {
    if (authStore.signUp.userRequiresConfirmation) {
      showConfirmationForm.value = true;
    } else {
      const signInSuccess = await authStore.initSignIn({ email: emailLower, password: password.value });
      if (signInSuccess) {
        router.push((route.query.redirect as RouteLocationRaw) || '/');
      } else {
        router.push('/');
      }
    }
  }
};

const userIsConfirmed = async () => {
  applicationStore.notifyUser({ message: 'Your email address is now verified. Please sign in to continue.' });
  const signInSuccess = await authStore.initSignIn({ email: email.value.toLowerCase(), password: password.value });
  if (signInSuccess) {
    router.push((route.query.redirect as RouteLocationRaw) || '/');
  } else {
    router.push('/signin');
  }
};
</script>
