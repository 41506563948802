<template>
  <v-banner v-if="showBanner" :text="bannerMessage" sticky style="z-index: 9999" bg-color="error">
    <template #prepend><v-icon class="mt-4">mdi-alert-circle-outline</v-icon></template>
    <template #actions><v-btn-var to="/billing/payment-methods/new">Add payment method now</v-btn-var></template>
  </v-banner>
  <div v-else></div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useBillingStore } from '@/store/billing';

const props = defineProps({
  message: {
    type: String,
    default: '',
  },
});

const billingStore = useBillingStore();
const route = useRoute();
const bannerMessage = computed(
  () => props.message || 'Action required: No billing payment method provided. Go to Billing and add a payment method to continue using yetipay.me',
);
const showBanner = computed(() => {
  if (route.meta.hideBillingWarningBanner) {
    return false;
  }
  return billingStore.showNoPaymentMethodsWarning;
});
</script>
