<template>
  <v-sheet :class="mobile ? 'mx-4' : 'mx-15'" class="mb-15'">
    <h1 class="sentence-case text-primary mb-5">{{ name }}</h1>
    <BillingNotificationBanner class="mb-5" />
    <slot></slot>
  </v-sheet>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify';

import BillingNotificationBanner from '@/components/Billing/BillingNotificationBanner.vue';

defineProps<{ name: string }>();
const { mobile } = useDisplay();
</script>
