import { myAxios } from '@/plugins/api';
import { Amount } from './common-types';

export interface Session {
  stripeCustomerId: string;
  stripePublicKey: string;
  paymentToken?: string;
}

/**
 * Reduced type compared to what Stripe's type is. Just what we use, but there is more available.
 */
export interface PaymentMethod {
  id: string;
  type: 'sepa_debit' | 'bacs_debit' | 'card';
  created: number;
  billing_details: {
    name: string;
  };
  sepa_debit?: {
    country: string;
    last4: string;
  };
  bacs_debit?: {
    sort_code: string;
  };
  card?: {
    brand: string;
    country: string;
    last4: string;
    exp_month: number;
    exp_year: number;
  };
}

export interface ListInvoicesParams {
  lastKey?: string;
  createdBetween?: number[];
  invoiceNumber?: string;
}

export interface Invoice {
  invoiceNumber: string;
  billingPeriod: string;
  dueDate: string;
  amount: Amount;
  status: InvoiceStatus;
  pdfLink?: string;
}
export enum InvoiceStatus {
  Upcoming = 'upcoming',
  Open = 'open',
  Paid = 'paid',
  Void = 'void',
  Overdued = 'overdued',
}

class BillingApi {
  private readonly merchantId: () => string;
  constructor(merchantId: () => string) {
    this.merchantId = merchantId;
  }

  public async createCheckoutSession(): Promise<Session> {
    return myAxios.post(`/v1/merchants/${this.merchantId()}/billing/session`, {}).then((response) => response.data.data);
  }

  public async fetchBillingSettings(): Promise<{
    stripeCustomerId: string;
    paymentMethods: PaymentMethod[];
    defaultPaymentMethod?: string;
    hasRates: boolean;
    hasSignedRates: boolean;
    ratesSignUrl: string;
  }> {
    return myAxios.get(`/v1/merchants/${this.merchantId()}/billing-settings`).then((response) => response.data.data);
  }

  public async setDefaultPaymentMethod(paymentMethodId: string): Promise<void> {
    return myAxios.put(`/v1/merchants/${this.merchantId()}/billing-settings/payment-methods/${paymentMethodId}/default`, {});
  }

  public async deletePaymentMethod(paymentMethodId: string): Promise<void> {
    return myAxios.delete(`/v1/merchants/${this.merchantId()}/billing-settings/payment-methods/${paymentMethodId}`);
  }

  public async listInvoices(params?: ListInvoicesParams): Promise<{ items: Invoice[]; lastKey: string | undefined }> {
    const createdBetween = params?.createdBetween ? encodeURIComponent(params.createdBetween.join(',')) : undefined;
    return myAxios
      .get(`/v1/merchants/${this.merchantId()}/invoices`, {
        params: { lastKey: params?.lastKey, createdBetween, invoiceNumber: params?.invoiceNumber },
      })
      .then((response) => response.data);
  }
}

export default BillingApi;
