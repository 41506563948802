import App from '@/App.vue';

import { createApp, nextTick } from 'vue';

import { registerPlugins } from '@/plugins';

import '@/utils/amplify';
import CountryFlag from 'vue-country-flag-next';

import * as Sentry from '@sentry/vue';

import router from '@/router';

const app = createApp(App);

registerPlugins(app);
app.use(router);
app.component('CountryFlag', CountryFlag);

app.config.globalProperties.$posthog.onFeatureFlags(function () {
  console.log('feature flags loaded');
});

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENVIRONMENT,
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

  tracesSampleRate: 1.0,

  tracePropagationTargets: ['localhost', /^https:\/\/basecamp\.yetipay\.me/, /^https:\/\/basecamp\.dev01\.yeti\.host/],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

router.afterEach((to, from, failure) => {
  if (!failure) {
    nextTick(() => {
      app.config.globalProperties.$posthog.capture('$pageview', { path: to.fullPath });
    });
  }
});

router.onError((error, to) => {
  // https://github.com/vitejs/vite/issues/11804#issuecomment-1406182566
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    console.error('Amplify deployment error', error);
    window.location.href = to.fullPath;
    window.location.reload();
  }
});

app.mount('#app');

export default app;
