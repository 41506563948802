<template>
  <v-alert v-if="transactionsStore.transactionsError" type="error">
    Issue occurred while gathering your transactions. Please refresh or contact TableYeti support.
  </v-alert>

  <v-skeleton-loader v-else-if="!transactionsStore.transactions" type="table" />

  <div v-else>
    <h3 class="text-primary mb-5">
      <span class="on-background-variant">Total today: </span>
      <span v-if="transactionsStore.transactionsTotals?.totalsAmount">
        {{ displayAmount(transactionsStore.transactionsTotals?.totalsAmount) }}
        <span v-if="transactionsStore.transactionsTotals?.tipsAmount.value > 0">
          ({{ displayAmount(transactionsStore.transactionsTotals?.tipsAmount) }} tips)
        </span>
      </span>
      <span v-else>-</span>
      <v-tooltip :text="transactionsTotalsTooltip!" :disabled="!transactionsTotalsTooltip" location="bottom">
        <template #activator="{ props }">
          <span v-bind="props"><v-icon v-if="transactionsTotalsTooltip" color="primary">mdi-information-outline</v-icon></span>
        </template>
      </v-tooltip>
    </h3>
    <v-text-field v-model="pspReference" label="Search by PSP ref." clearable @input="() => (pspReference = pspReference?.toUpperCase())" />
    <SiteSelector v-model="transactionsStore.transactionsParams.siteId" />
    <VueDatePicker
      v-model="transactionsStore.transactionsParams.dates"
      locale="en-UK"
      format="dd/MM/yyyy"
      placeholder="Filter by date(s)"
      :range="{ noDisabledRange: true }"
      :max-date="new Date()"
      :enable-time-picker="false"
      :preset-dates="datePickerPresetRanges"
    />
    <v-data-table
      :headers="transactionsTableHeaders"
      :items="transactionsStore.transactions"
      :loading="transactionsStore.transactionsLoading"
      no-data-text="No transactions for the selected search criterias."
      :sort-by="[{ key: 'createdDate', order: 'desc' }]"
      item-value="pspReference"
      items-per-page="-1"
      :expanded="[]"
      show-expand
      expand-on-click
    >
      <template #top>
        <v-toolbar style="background-color: rgb(var(--v-theme-background))" class="mt-5">
          <v-alert v-if="transactionsStore.transactionsNotAllowed" type="warning" variant="outlined" density="compact">
            Please select a site to view transactions.
          </v-alert>
          <v-spacer v-else />
          <v-tooltip location="bottom" :disabled="!reportDisabled">
            <template #activator="{ props }">
              <div v-bind="props">
                <v-btn color="secondary" :disabled="reportDisabled" :loading="reportLoading" @click="downloadReport">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </div>
            </template>
            {{ reportDisabledText }}
          </v-tooltip>
          <v-btn color="secondary" @click="transactionsStore.refresh"><v-icon>mdi-refresh</v-icon></v-btn>
        </v-toolbar>
      </template>

      <template #expanded-row="{ columns, item }">
        <td :colspan="columns.length" class="pa-4">
          <div>
            <div v-if="item.paymentLinkId">
              <strong>Payment Link ID:</strong> <kbd>{{ item.paymentLinkId }}</kbd>
              <br />
            </div>
            <strong>Transaction ID:</strong> <kbd>{{ item.transactionId }}</kbd>
            <br />
            <strong>Merchant Reference:</strong> {{ item.adyenMerchantReference }}
            <br />
            <div v-if="!item.successBool"><strong>Reason:</strong> {{ item.reason }}</div>
            <div v-if="item.refunds">
              <p class="font-weight-bold text-uppercase text-primary mt-3">Refunds:</p>
              <v-table density="compact" hover>
                <template #default>
                  <thead>
                    <tr>
                      <th class="text-left">PSP Reference</th>
                      <th class="text-left">Booking date</th>
                      <th class="text-left">Amount</th>
                      <th class="text-left">Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="refund in item.refunds" :key="refund.pspReference">
                      <td>
                        <kbd>{{ refund.pspReference }}</kbd>
                      </td>
                      <td>{{ refund.bookingDate }}</td>
                      <td>{{ displayAmount(refund.amount, true) }}</td>
                      <td>{{ refund.reason }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-table>
            </div>
          </div>
        </td>
      </template>
      <template #item.pspReference="{ item }">
        <kbd>{{ item.pspReference }}</kbd>
      </template>
      <template #item.paymentMethod="{ item }">
        <PaymentMethod v-model="item.paymentMethod" />
      </template>
      <template #item.amount="{ item }">
        {{ displayAmount(item.amount) }}
      </template>
      <template #item.tip="{ item }">
        {{ displayAmount(item.includedTipAmount ?? { value: 0, currency: item.amount.currency }) }}
      </template>
      <template #item.status="{ item }">
        <span class="text-uppercase">{{ item.status }}</span>
      </template>
      <template #item.actions="{ item }">
        <v-btn-nd
          :disabled="!item.enableRefund"
          @click="
            transactionSelectedForRefund = item;
            refundConfirmDialog = true;
          "
          >Refund</v-btn-nd
        >
      </template>
      <template #bottom></template>
    </v-data-table>

    <v-pagination
      v-model="transactionsStore.page"
      :length="transactionsStore.totalPages"
      :disabled="transactionsStore.transactionsLoading"
      color="secondary"
      rounded="sm"
    >
      <template #next="{ disabled, onClick }">
        <v-btn-nd v-if="!transactionsStore.finalPageKnown" disabled icon="mdi-dots-horizontal" variant="text" size="default" />
        <v-btn-nd :disabled="disabled || transactionsStore.isFinalPage" icon="mdi-chevron-right" variant="text" size="default" @click="onClick" />
      </template>
    </v-pagination>
  </div>

  <RefundDialog v-model="refundConfirmDialog" :transaction="transactionSelectedForRefund" />
  <DownloadDialog v-model="reportDownloadDialog" :report-id="reportId" />
</template>

<script setup lang="ts">
import { ref, onBeforeMount, onMounted, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import BigNumber from 'bignumber.js';
import { differenceInDays, endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek, startOfYear, subDays, subMonths, subWeeks } from 'date-fns';
import { useTransactionsStore, prepareDatesForApi } from '@/store/transactions';
import SiteSelector from '@/components/SiteSelector.vue';
import RefundDialog from '@/components/RefundDialog.vue';
import DownloadDialog from '@/components/DownloadDialog.vue';
import PaymentMethod from '@/components/PaymentMethod.vue';
import { inject } from 'vue';
import { API } from '@/plugins/api';
import { Amount } from '@/api/merchant-service/common-types';
import { TransactionV1 } from '@/api/merchant-service/transaction';

const api = inject<API>('api');
const route = useRoute();
const transactionsStore = useTransactionsStore();

const transactionsTableHeaders = [
  { title: 'PSP Reference', value: 'pspReference' },
  { title: 'Site', value: 'siteName' },
  { title: 'Status', value: 'status' },
  { title: 'Amount', value: 'amount' },
  { title: 'Tip', value: 'tip' },
  { title: 'Payment Method', value: 'paymentMethod' },
  { title: 'Created Date', value: 'createdDate' },
  { title: 'Actions', value: 'actions' },
];

const startOfDayToday = startOfDay(new Date());
const endOfDayToday = endOfDay(new Date());
const datePickerPresetRanges = ref([
  { label: 'Today', value: [startOfDayToday, endOfDayToday] },
  { label: 'Yesterday', value: [subDays(startOfDayToday, 1), subDays(endOfDayToday, 1)] },
  { label: 'This week', value: [startOfWeek(startOfDayToday, { weekStartsOn: 1 }), endOfDayToday] },
  { label: 'Last week', value: [startOfWeek(subWeeks(startOfDayToday, 1), { weekStartsOn: 1 }), endOfWeek(subWeeks(endOfDayToday, 1), { weekStartsOn: 1 })] },
  { label: 'This month', value: [startOfMonth(startOfDayToday), endOfDayToday] },
  { label: 'Last month', value: [startOfMonth(subMonths(startOfDayToday, 1)), endOfMonth(subMonths(endOfDayToday, 1))] },
  { label: 'Last 90 days', value: [subDays(startOfDayToday, 90), endOfDayToday] },
  { label: 'Last 6 months', value: [startOfMonth(subMonths(startOfDayToday, 6)), endOfMonth(subMonths(endOfDayToday, 1))] },
  { label: 'This year', value: [startOfYear(startOfDayToday), endOfDayToday] },
]);

const transactionSelectedForRefund = ref<TransactionV1 | undefined>(undefined);
const refundConfirmDialog = ref(false);

onBeforeMount(() => {
  if (transactionsStore.transactions) {
    // In the case where the store is already initialized, we don't want to override the params
    saveParamsToUrl();
    transactionsStore.refresh();
  } else {
    // Changing the params here will trigger a refresh (see the watch below)
    if (route.query.psp) {
      pspReference.value = route.query.psp as string;
      return;
    }
    if (route.query.siteId) {
      transactionsStore.transactionsParams.siteId = route.query.siteId as string;
    }
    if (route.query.dates) {
      transactionsStore.transactionsParams.dates = (route.query.dates as string).split(',').map((date: string) => new Date(date));
    } else {
      transactionsStore.transactionsParams.dates = [startOfDay(new Date()), endOfDay(new Date())]; // Today
    }
  }
});
watch(
  () => transactionsStore.transactionsParams,
  () => {
    if (transactionsStore.transactionsParams.pspReference) {
      transactionsStore.transactionsParams.dates = undefined;
    }
    saveParamsToUrl();
    transactionsStore.refresh();
  },
  { deep: true },
);
watch(
  () => transactionsStore.page,
  () => transactionsStore.setPage(transactionsStore.page),
);
const pspReference = ref<string | undefined>(undefined);
watch(pspReference, () => {
  if (!/[a-z]/.exec(pspReference.value ?? '')) {
    transactionsStore.transactionsParams.pspReference = pspReference.value;
  }
});
onMounted(() => transactionsStore.getTransactionTotals());
watch(
  () => transactionsStore.transactionsParams.siteId,
  () => transactionsStore.getTransactionTotals(),
);
const transactionsTotalsTooltip = computed(() => {
  if (!transactionsStore.transactionsParams.siteId) {
    return 'Select a site to view the total transactions for today.';
  } else if (!transactionsStore.transactionsTotals) {
    return undefined; // still loading
  }
  if (transactionsStore.transactionsTotals.totalsCount === 0) {
    return 'No transactions today';
  }
  return 'Sum of all transactions since midnight';
});

const saveParamsToUrl = () => {
  const queryParams: string[] = [];
  if (transactionsStore.transactionsParams.dates && transactionsStore.transactionsParams.dates.length > 0) {
    // VueDatePicker sets the second date as null when only 1 is selected
    queryParams.push(
      `dates=${transactionsStore.transactionsParams.dates
        .filter((d) => !!d)
        .map((date: Date) => date.toISOString())
        .join(',')}`,
    );
  }
  if (transactionsStore.transactionsParams.siteId) {
    queryParams.push(`siteId=${transactionsStore.transactionsParams.siteId}`);
  }
  if (transactionsStore.transactionsParams.pspReference) {
    queryParams.push(`psp=${transactionsStore.transactionsParams.pspReference}`);
  }
  history.pushState({}, '', route.path + (queryParams.length > 0 ? '?' + queryParams.join('&') : ''));
};

const displayAmount = (amount: Amount, invert = false) => {
  return new BigNumber(amount.value)
    .dividedBy(100)
    .multipliedBy(invert ? -1 : 1)
    .toNumber()
    .toLocaleString('en-UK', {
      style: 'currency',
      currency: amount.currency,
    });
};

const reportLoading = ref(false);
const reportDisabled = computed(() => {
  const noResult = transactionsStore.transactions?.length === 0;
  const noDatesNorPsp = !transactionsStore.transactionsParams.dates && !transactionsStore.transactionsParams.pspReference;
  const bothDatesAndPsp = !!transactionsStore.transactionsParams.dates && !!transactionsStore.transactionsParams.pspReference;
  const datesTooFarApart =
    transactionsStore.transactionsParams.dates?.length === 2 &&
    differenceInDays(transactionsStore.transactionsParams.dates[1], transactionsStore.transactionsParams.dates[0]) > 186;
  return noResult || reportLoading.value || noDatesNorPsp || bothDatesAndPsp || datesTooFarApart;
});
const reportDisabledText = computed(() => {
  if (reportLoading.value) {
    return 'Generating report...';
  }
  if (transactionsStore.transactionsLoading) {
    return 'Loading transactions...';
  }
  if (transactionsStore.transactions?.length === 0) {
    return 'No transactions to report';
  }
  if (!transactionsStore.transactionsParams.dates && !transactionsStore.transactionsParams.pspReference) {
    return 'Select a date range or search by PSP reference to generate a report';
  }
  if (!!transactionsStore.transactionsParams.dates && !!transactionsStore.transactionsParams.pspReference) {
    return 'Select either a date range or search by PSP reference to generate a report';
  }
  if (
    transactionsStore.transactionsParams.dates?.length === 2 &&
    differenceInDays(transactionsStore.transactionsParams.dates[1], transactionsStore.transactionsParams.dates[0]) > 186
  ) {
    return 'Date range too large. Please select a range of 6 months or less.';
  }
  return 'Generating report...';
});
const reportId = ref<string | undefined>(undefined);
const reportDownloadDialog = ref(false);
const downloadReport = async () => {
  reportLoading.value = true;
  try {
    const result = await api!.report.createTransactionReport(
      transactionsStore.transactionsParams.siteId,
      prepareDatesForApi(transactionsStore.transactionsParams.dates),
      transactionsStore.transactionsParams.pspReference,
    );
    reportId.value = result;
    reportDownloadDialog.value = true;
  } catch (e) {
    console.error(e);
  } finally {
    reportLoading.value = false;
  }
};
</script>
