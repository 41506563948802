<template>
  <v-dialog v-model="dialogState" max-width="500px">
    <v-card>
      <template #title><span class="text-primary">Request refund</span></template>
      <template #text>
        <p v-if="props.transaction!.status !== 'partially-refunded'" class="mb-2">
          You can refund this transaction up to a maximum of the full transaction amount, {{ displayAmount(props.transaction!.amount!) }}. For a partial refund,
          edit the refund amount below.
        </p>
        <p v-else class="mb-2">
          {{ displayAmount(leftToRefundAmount) }} has already been refunded from this transaction. You can refund further up to a maximum of the remaining
          amount, {{ displayAmount(maxRefundAmount) }}.
        </p>
        <p class="mb-2">
          PSP reference: <kbd>{{ props.transaction!.pspReference }}</kbd
          ><br />
          Date of transaction: {{ props.transaction!.createdDate }}<br />
          Full amount: {{ displayAmount(props.transaction!.amount!) }}
        </p>
        <v-text-field
          v-model.number="amountToRefund"
          type="number"
          :min="0"
          :max="Math.ceil(maxRefund)"
          :prefix="getCurrencySymbol('en-UK', props.transaction?.amount?.currency ?? 'GBP')"
          label="Amount to refund"
        />
      </template>
      <template #actions>
        <v-btn-nd class="ms-4 my-4" @click="dialogState = !dialogState">Cancel</v-btn-nd>
        <v-spacer />
        <v-btn class="bg-primary on-primary me-4 my-4" :disabled="disabled" :loading="transactionsStore.refundLoading" @click="refund()">Submit</v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import BigNumber from 'bignumber.js';
import { ref, computed, watch } from 'vue';
import { useTransactionsStore } from '@/store/transactions';
import { useApplicationStore } from '@/store/application';
import { onUpdated } from 'vue';
import { Amount } from '@/api/merchant-service/common-types';
import { TransactionV1 } from '@/api/merchant-service/transaction';
import { displayAmount } from '@/utils/format';

const dialogState = defineModel<boolean>({ required: true });
const props = defineProps<{ transaction?: TransactionV1 }>();

const transactionsStore = useTransactionsStore();
const applicationStore = useApplicationStore();

const maxRefund = computed(() => {
  const sumRefunds = props.transaction?.refunds?.reduce((acc, refund) => acc + refund.amount.value, 0) ?? 0;
  const amountLeft = new BigNumber(props.transaction?.amount?.value ?? 0).minus(sumRefunds).toNumber();
  return new BigNumber(amountLeft).dividedBy(100).toNumber();
});
const maxRefundAmount = computed<Amount>(
  () =>
    ({
      value: maxRefund.value * 100,
      currency: props.transaction?.amount?.currency,
    }) as Amount,
);
const leftToRefundAmount = computed<Amount>(
  () =>
    ({
      value: props.transaction!.amount!.value - maxRefund.value * 100,
      currency: props.transaction?.amount?.currency,
    }) as Amount,
);
const amountToRefund = ref(0);
onUpdated(() => (amountToRefund.value = maxRefund.value));
watch(amountToRefund, (value) => {
  if (value > maxRefund.value) amountToRefund.value = maxRefund.value;
});
const disabled = computed(() => !amountToRefund.value || amountToRefund.value <= 0 || amountToRefund.value > maxRefund.value);

const refund = async () => {
  if (!props.transaction || !props.transaction.amount) return;
  const amount: Amount = {
    value: new BigNumber(amountToRefund.value).multipliedBy(100).toNumber(),
    currency: props.transaction.amount.currency,
  };
  await transactionsStore.refundTransaction(props.transaction.pspReference, amount);
  const message = transactionsStore.refundError
    ? `Unable to refund transaction. ${transactionsStore.refundError}`
    : 'It may take a few minutes for your refund to show in the transactions list.';
  applicationStore.notifyUser({ message, type: transactionsStore.refundError ? 'error' : 'success' });
  dialogState.value = false;
};

const getCurrencySymbol = (locale: string, currency: string) =>
  (0).toLocaleString(locale, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim();
</script>
