import { required, helpers, minLength } from '@vuelidate/validators';

const containsNumber = (value: string) => /\d/.test(value);
const containsUppercase = (value: string) => /[A-Z]/.test(value);
const containsLowercase = (value: string) => /[a-z]/.test(value);
const passwordValidationMessage = 'Passwords must be at least 8 characters long, contain at least one number, one uppercase letter, and one lowercase letter.';

const siteNameRegex = helpers.regex(/^[a-zA-Z0-9 _-]+$/);
const deviceOrderPhoneRegex = helpers.regex(/^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/);

export const passwordValidations = {
  required: helpers.withMessage('Required', required),
  minLength: helpers.withMessage(passwordValidationMessage, minLength(8)),
  containsNumber: helpers.withMessage(passwordValidationMessage, containsNumber),
  containsUppercase: helpers.withMessage(passwordValidationMessage, containsUppercase),
  containsLowercase: helpers.withMessage(passwordValidationMessage, containsLowercase),
};

export const siteNameValidator = helpers.withMessage('Site name can only contain letters, number, spaces hyphens and underscores. ', siteNameRegex);

export const requiredField = helpers.withMessage('Required', required);

export const deviceOrderPhone = helpers.withMessage('Please enter a valid phone number', deviceOrderPhoneRegex);
