<template>
  <v-card class="pa-8 rounded-lg">
    <h2 class="text-primary font-weight-regular mb-2">Welcome to yetipay!</h2>
    <p class="mb-5 font-weight-light text-grey-darken-2">
      There are a few more steps for you to take before you can order a device and start trading. Nearly there!
    </p>

    <ImpossibleOrderItem :success="!missingPaymentMethod" action-text="Add payment method" action-link="/billing/payment-methods/new">
      <span v-if="missingPaymentMethod">Add a billing payment method</span>
      <span v-else>Payment method added</span>
    </ImpossibleOrderItem>

    <ImpossibleOrderItem v-if="!isLegacyCustomer" :success="!missingDeal">
      <span v-if="missingDeal">
        Get your custom Yetipay rates applied. Chat to your sales rep or account manager. If you still need help, contact
        <a href="mailto:help@yetipay.me">help@yetipay.me</a>

        <v-dialog v-if="isStaff" max-width="500">
          <template #activator="{ props: activatorProps }">&nbsp;<v-btn v-bind="activatorProps" color="error" size="default">Link deal</v-btn></template>
          <template #default="{ isActive }">
            <v-card title="Link deal to company">
              <v-card-text>
                <p>You are about to link your company with a deal.</p>
                <br />
                <v-text-field v-model="dealReference" label="Deal reference" />
              </v-card-text>
              <v-card-actions>
                <v-btn-nd text="Cancel" @click="isActive.value = false" />
                <v-spacer></v-spacer>
                <v-btn text="Link" :disabled="!dealReference || dealReference.length < 1" class="bg-primary on-primary" @click="associateDeal(isActive)" />
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </span>
      <span v-else>Rates applied</span>
    </ImpossibleOrderItem>

    <ImpossibleOrderItem v-if="!isLegacyCustomer" :success="!dealNotSigned" action-text="Sign now" :action-link="dealUrl" action-link-tab>
      <span v-if="dealNotSigned">Sign your contract with Yetipay</span>
      <span v-else>Contract signed</span>
    </ImpossibleOrderItem>

    <p class="mt-5 font-italic font-weight-light text-right text-grey-darken-2">
      Need help? Contact <a href="mailto:help@yetipay.me">help@yetipay.me</a> or speak to your account manager.
    </p>
  </v-card>
</template>

<script setup lang="ts">
import { Ref, ref } from 'vue';
import ImpossibleOrderItem from '@/components/Billing/ImpossibleOrderItem.vue';

defineProps<{
  missingPaymentMethod: boolean;
  missingDeal: boolean;
  dealNotSigned: boolean;
  dealUrl?: string;
  isStaff: boolean;
  isLegacyCustomer?: boolean;
}>();

const emit = defineEmits<{
  associateDealReference: [value: string];
}>();

const dealReference = ref<string | undefined>(undefined);
const associateDeal = (isActive: Ref<boolean>) => {
  if (dealReference.value) {
    emit('associateDealReference', dealReference.value);
    isActive.value = false;
  }
};
</script>
